import {
  Dialog,
  DialogContent,
  Grid,
  Link,
  useMediaQuery,
  useTheme,
} from '@mui/material'
import { useIntersection } from 'react-use'

import AppBar from '@mui/material/AppBar'
import Box from '@mui/material/Box'
import CssBaseline from '@mui/material/CssBaseline'
import Toolbar from '@mui/material/Toolbar'
import * as React from 'react'
import talkToPharmacistImageSrc from './../system/images/talk_to_pharmacist_illustration.svg'

import SouthIcon from '@mui/icons-material/South'
import thankyouending from '../../images/prereg/thank_you_end_anime.gif'
import thankyougiflonger from '../../images/prereg/thank_you_white_anime.gif'

import ExitToAppIcon from '@mui/icons-material/ExitToApp'
import { useEffect, useRef, useState } from 'react'
import Confetti from 'react-confetti'
import { useHistory } from 'react-router'
import { useWindowSize } from 'react-use'
import { useSTranslate } from 'src/app/hooks/useSTranslate'
import { SavingStrategy } from 'src/app/strategies/types'
import SButton from 'src/app/system/customcomponents/SButton'
import STypography from 'src/app/system/customcomponents/STypography'
import { StrategyCarousel } from 'src/app/system/strategycarousel/StrategyCarousel'
import { SPACING } from 'src/app/system/theme2'
import { ThemedSVGRemote } from 'src/app/v2/ThemedSVGRemote'
import { useMemberAppContext } from '../MemberAppContext'
import { nativeApi_setBackButtonVisible } from '../apiexec/utils'
import { VideoWithPoster } from '../strategies/VideoWithPoster'
import { useSColors } from '../styles/scripta-theme'
import feedbackImgSrc from '../v2/images/feedback.svg'
import './arrowdown.css'

import StepCard from 'src/app/system/StepCard'
import piggyBankSrc from '../system/images/piggy_bank.svg'
import arrowHorizontalSrc from '../v2/images/arrow_horizontal.svg'
import arrowVerticalSrc from '../v2/images/arrow_vertical.svg'

import doctorPriceSrc from '../v2/images/doctor-price-good.svg'
import savingsReportExampleSrc from '../v2/images/savings_report_example.svg'

import { alpha } from '@mui/material/styles'

function MyConfetti() {
  const { width, height } = useWindowSize()

  return (
    <Confetti
      numberOfPieces={450}
      width={width - 30}
      height={height}
      recycle={false}
      initialVelocityY={10}
    />
  )
}

function ArrowDownIndicator() {
  //extra large down icon
  //dissapears on small screens and on first scroll
  const theme = useTheme()
  const isSmall = useMediaQuery(theme.breakpoints.down('sm'))
  //is tablet also we dont want to show
  const isTablet = useMediaQuery(theme.breakpoints.down('md'))

  const [showArrow, setShowArrow] = useState(true)

  if (isSmall || isTablet || !showArrow) {
    return <></>
  }
  return (
    <Box
      sx={{
        position: 'absolute',
        // right: '175px',
        // right: 'calc(50vw - 80px)',
        left: 'calc(50%)',
        marginLeft: '-40px',
        marginTop: '100px',

        // bottom: '200px',
        zIndex: 10000,
      }}
    >
      <div>
        <SouthIcon
          className="arrow"
          sx={{
            position: 'absolute',
            // right: '175px',
            // bottom: '70px',
            fontSize: '70px',
            color: '#3C4142',
          }}
        />
      </div>
    </Box>
  )
  // return (
  //   <Box display="flex">
  //     <Box sx={{ marginLeft: 'auto', marginRight: '40px' }}>
  //       {/* <SouthIcon
  //         sx={{
  //           position: 'absolute',
  //           right: '175px',
  //           bottom: '70px',
  //           fontSize: '80px',
  //           color: '#3C4142',
  //         }}
  //       /> */}
  //     </Box>
  //   </Box>
  // )
}

function ThankYouHeader({
  isSmall,
  mode,
  onStartSavingNowClicked,
  hideArrowDown,
  showConfetti,
}: {
  isSmall: boolean
  mode?: 'register' | 'prereg'
  onStartSavingNowClicked?: () => void
  hideArrowDown: boolean
  showConfetti: boolean
}) {
  const { COLORS } = useSColors()

  const [showThankYouEnd, setShowThankYouEnd] = useState(false)
  const { t } = useSTranslate(['prereg', 'register'])

  const titleTextContent =
    mode === 'prereg'
      ? t('prereg:forPreregistering')
      : t('register:forRegistering')
  const bodyTextContent =
    mode === 'prereg'
      ? t('prereg:weWillLetYouKnow')
      : t('register:whileYouAreHere')

  useEffect(() => {
    // onCodeInitialized(result)
    setTimeout(() => {
      setShowThankYouEnd(true)
    }, 3000)
  }, [])

  return (
    <Box
      sx={{
        width: '100%',
        minHeight: '300px',
        background: COLORS.BACKGROUND_GRADIENT,
        paddingTop: '50px',
        position: 'relative',
        overflow: 'hidden',
      }}
    >
      <Grid
        container
        justifyContent="center"
        sx={{
          paddingLeft: isSmall ? '30px' : '0px',
          paddingRight: isSmall ? '30px' : '0px',
        }}
      >
        {showConfetti && (
          <Grid item xs={12}>
            <MyConfetti />
          </Grid>
        )}
        <Grid item xs={12} sx={{ textAlign: 'center', marginTop: '20px' }}>
          <img
            alt="Thank you"
            data-testid="thank-you-gif"
            src={!showThankYouEnd ? thankyougiflonger : thankyouending}
            width={isSmall ? '275px' : '380px'}
            height={isSmall ? '275px' : '380px'}
          />
        </Grid>
        <Grid item xs={12}>
          <STypography
            variant={isSmall ? 'title3_semibold' : 'title1_semibold'}
            sx={{
              color: '#FFF',
              textAlign: 'center',
              fontSize: isSmall ? 24 : 40,
            }}
          >
            {titleTextContent}
          </STypography>
        </Grid>
        {mode === 'register' ? (
          <>
            <Grid item xs={12}>
              <WelcomeHeaderSubtitle isSmall={isSmall} />
            </Grid>
            <Grid item container xs={12} alignItems={'center'}>
              <Grid
                item
                sx={{
                  margin: 'auto',
                  paddingTop: '20px',
                  paddingBottom: '10px',
                }}
              >
                <SButton
                  noCaps
                  data-testid="register-view-my-savings-button"
                  onClick={() => {
                    if (onStartSavingNowClicked) {
                      onStartSavingNowClicked()
                    }
                  }}
                >
                  {t('register:accountBtnStartSavingNow')}
                </SButton>
              </Grid>
            </Grid>
          </>
        ) : null}
        <Grid item xs={12}>
          <STypography
            variant={isSmall ? 'body_regular' : 'title3_regular'}
            sx={{
              textAlign: 'center',

              color: '#FFF',
              lineHeight: isSmall ? '19.49px' : '29.23px',

              marginLeft: 'auto',
              marginRight: 'auto',
              marginTop: isSmall ? '5px' : '15px',
              marginBottom: isSmall ? '35px' : '35px',
              padding: isSmall ? '10px 10px' : '0px 30px',
              maxWidth: '800px',
            }}
          >
            {bodyTextContent}
          </STypography>
        </Grid>
        <Grid item sx={{ marginBottom: isSmall ? '170px' : '240px' }}></Grid>
      </Grid>
      <WhiteElipsisUpSlice isSmall={isSmall} hideArrowDown={hideArrowDown} />
    </Box>
  )
}

function WelcomeHeaderSubtitle({ isSmall }: { isSmall: boolean }) {
  const { t } = useSTranslate(['register'])
  return (
    <Box
      style={{
        // padding: '5px 10px',
        margin: 'auto',
        marginTop: isSmall ? 0 : 0,
        marginBottom: isSmall ? 5 : 0,
      }}
    >
      <STypography
        variant={isSmall ? 'bodybig_semibold' : 'title3_semibold'}
        sx={{
          color: '#FFF',
          textAlign: 'center',
        }}
      >
        {t('register:accountIsNowSetup')}
      </STypography>
    </Box>
  )
}

function WhiteElipsisUpSlice({
  isSmall,
  hideArrowDown,
}: {
  isSmall: boolean
  hideArrowDown: boolean
}) {
  return (
    <Box
      style={{
        width: '120%',
        left: '-10%',
        borderRadius: '200%',
        backgroundColor: 'white',
        height: isSmall ? '150px' : '420px',
        position: 'absolute',
        zIndex: 1,
        bottom: isSmall ? '-85px' : '-325px',
      }}
    >
      {!hideArrowDown && <ArrowDownIndicator />}
    </Box>
  )
}

export function WhiteElipsisDownSlice({ isSmall }: { isSmall: boolean }) {
  return (
    <Box
      style={{
        width: '120%',
        left: '-10%',
        borderBottomLeftRadius: '200%',
        borderBottomRightRadius: '200%',
        backgroundColor: 'white',
        height: isSmall ? '75px' : '210px',
        position: 'relative',
        zIndex: 0,
        // marginTop: isSmall ? '-85px' : '-125px',
        overflow: 'hidden',
      }}
    />
  )
}

export function WelcomePreregDialog({
  onGoHomeFromThankYouClicked,
  mode = 'prereg',
}: {
  onGoHomeFromThankYouClicked: () => void
  mode: 'register' | 'prereg'
}) {
  const { COLORS } = useSColors()
  const [mobileOpen, setMobileOpen] = React.useState(false)
  const [arrowDownVisible, setArrowDownVisible] = useState(true)
  //put in dialog in case we need to reuse this from login page when prreg users login
  const [mainDialogOpen, setMainDialogOpen] = React.useState(true)

  const theme = useTheme()
  const isMedium = useMediaQuery(theme.breakpoints.down('md'))
  const isSmall = useMediaQuery(theme.breakpoints.down('sm')) || isMedium

  const { t } = useSTranslate(['common', 'login', 'register'])

  const NAVBAR_SCROLL_THRESHOLD = isSmall ? 50 : 450

  const scrollContainerRef: any = useRef(null)
  const [scrollPosition, setScrollPosition] = useState(0)

  useEffect(() => {
    nativeApi_setBackButtonVisible(false)
    return () => {
      if (scrollContainerRef && scrollContainerRef.current) {
        scrollContainerRef.current.removeEventListener('scroll', handleScroll)
      }
    }
  }, [])

  function handleScroll() {
    const { scrollTop } = scrollContainerRef.current ?? { scrollTop: 0 }
    setScrollPosition(scrollTop)
  }

  return (
    <Box sx={{ display: 'flex' }}>
      <Dialog fullScreen={true} open={mainDialogOpen}>
        <DialogContent
          ref={(node) => {
            if (node && !scrollContainerRef.current) {
              scrollContainerRef.current = node
              scrollContainerRef.current.addEventListener(
                'scroll',
                handleScroll,
              )
            }
          }}
          sx={{
            padding: 0,
            overscrollBehavior: 'none',
            overflowX: 'hidden',
            width: '100%',
          }}
        >
          {!isSmall ? (
            <Box
              style={{
                height: 20,
                background: COLORS.BACKGROUND_GRADIENT,
              }}
            ></Box>
          ) : null}
          <AppBar
            component="nav"
            elevation={0}
            position="sticky"
            color={'transparent'}
            style={{
              background:
                scrollPosition > NAVBAR_SCROLL_THRESHOLD
                  ? COLORS.BACKGROUND_GRADIENT
                  : undefined,
              overflowX: 'hidden',
            }}
          >
            <Toolbar>
              <Grid
                container
                justifyContent={'space-between'}
                alignItems={'center'}
                sx={{
                  paddingLeft: isSmall ? '5px' : '20px',
                  paddingRight: isSmall ? '5px' : '20px',
                  paddingTop: '2.5px',
                }}
              >
                <Grid
                  item
                  sx={{ padding: '0px 0px 0px 0px', marginTop: '5px' }}
                >
                  <img
                    // src={
                    //   isSmall
                    //     ? homeLogoMobile
                    //     : theme.customized.whiteOnDarkLogoUrl
                    // }
                    src={
                      // isSmall
                      //   ? homeLogoMobile
                      theme.customized.whiteOnDarkLogoUrl
                    }
                    alt="Logo"
                    height={isSmall ? 31 : 40}
                  />
                </Grid>
                <Grid item sx={{ padding: '0px 0px 0px 0px' }}>
                  <HomeButton
                    mode={mode}
                    isSmall={isSmall}
                    onHomeClicked={() => {
                      setMainDialogOpen(false)
                      onGoHomeFromThankYouClicked()
                    }}
                    onScrolledOutOfSight={() => {
                      setArrowDownVisible(false)
                    }}
                  />
                </Grid>
              </Grid>
            </Toolbar>
          </AppBar>

          <Box component="main" sx={{ marginTop: '-65px' }}>
            <CssBaseline />
            <ThankYouHeader
              isSmall={isSmall}
              mode={mode}
              showConfetti={mode === 'prereg' ? true : false}
              onStartSavingNowClicked={() => {
                setMainDialogOpen(false)
                onGoHomeFromThankYouClicked()
              }}
              hideArrowDown={scrollPosition > 1}
            />
            <MeetScripta isSmall={isSmall} />
            {isSmall && <Box sx={{ minHeight: '40px' }}></Box>}
            <ProblemAndSolution isSmall={isSmall} />
            <HowToSaveWithScripta isSmall={isSmall} />

            {/* TWEAK THIS MINHEIGHT AND MARINGTOP BELOW WHITEELIPISSISDOWNSLICE to position */}
            <Box sx={{ minHeight: '270px' }}></Box>

            <Box
              sx={{
                position: 'relative',
                zIndex: 0,
              }}
            >
              <Box
                sx={{
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  right: 0,
                  bottom: 0,
                  background: COLORS.BACKGROUND_GRADIENT,
                  opacity: 0.5,
                }}
              />
              <Box sx={{ position: 'relative', zIndex: 1 }}>
                {/* Your content */}
                <WhiteElipsisDownSlice isSmall={isSmall} />
                <Box sx={{ marginTop: '-480px' }}>
                  <ScriptaStrategies isSmall={isSmall} />
                  <Box
                    sx={{
                      paddingLeft: isSmall ? '20px' : '0px',
                      paddingRight: isSmall ? '20px' : '0px',
                    }}
                  >
                    {mode === 'register' && (
                      <StartSavingWithScripta
                        isSmall={isSmall}
                        onExploreSavingsNow={() => {
                          setMainDialogOpen(false)
                          onGoHomeFromThankYouClicked()
                        }}
                      />
                    )}
                  </Box>
                </Box>
                <Box sx={{ minHeight: '100px' }}></Box>
              </Box>
            </Box>

            <Grid item xs={12}>
              <WelcomeFooter />
            </Grid>
          </Box>
        </DialogContent>
      </Dialog>
    </Box>
  )
}

function ScriptaStrategies({ isSmall }: { isSmall: boolean }) {
  const isVerticalLayout = useMediaQuery('(min-width:1195px)')
  const { COLORS } = useSColors()
  const [selectedStrategy, setSelectedStrategy] = useState<
    { strategy: SavingStrategy; youTubeId: string } | undefined
  >(undefined)

  useEffect(() => {
    setSelectedStrategy({
      strategy: SavingStrategy.AlternateGeneric,
      youTubeId: '3kReWCE60uM',
    })
  }, [])

  return (
    <>
      <Grid
        item
        xs={12}
        sx={{
          paddingTop: isSmall ? '200px' : '70px',
          paddingBottom: isSmall ? '20px' : '20px',
        }}
      >
        <STypography
          sx={{ textAlign: 'center', color: COLORS.PRIMARY_BRAND }}
          variant="title1_bold"
        >
          Scripta saving strategies explained
        </STypography>
      </Grid>
      <Grid
        container
        direction={isVerticalLayout ? 'row' : 'column'}
        sx={{
          maxWidth: '1015px',
          margin: 'auto',
          marginBottom: '20px',
          paddingLeft: isVerticalLayout ? '0px' : '20px',
          paddingRight: isVerticalLayout ? '0px' : '20px',
        }}
      >
        {/* Carousel - First column in vertical layout */}
        {isVerticalLayout && (
          <Grid item xs={12} md={3} sx={{ paddingTop: '2px' }}>
            <StrategyCarousel
              direction="vertical"
              onSlideClick={(s) => {
                setSelectedStrategy(s)
              }}
            />
          </Grid>
        )}

        {/* Video Player */}
        <Grid item xs={12} md={isVerticalLayout ? 9 : 12}>
          {selectedStrategy && (
            <Box
              sx={{
                overflow: 'hidden',
                marginTop: '5px',
                padding: isVerticalLayout ? '10px' : '0px',
                height: '100%',
                width: '100%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                minHeight: isVerticalLayout ? '400px' : 'auto',
              }}
            >
              <VideoWithPoster
                isSmallScreen={false}
                title={selectedStrategy.strategy}
                youtubeId={selectedStrategy.youTubeId}
              />
            </Box>
          )}
        </Grid>

        {/* Carousel - After video in horizontal layout */}
        {!isVerticalLayout && (
          <Grid item xs={12} sx={{ paddingTop: '20px' }}>
            <StrategyCarousel
              direction="horizontal"
              onSlideClick={(s) => {
                setSelectedStrategy(s)
              }}
            />
          </Grid>
        )}
      </Grid>
    </>
  )
}

function StartSavingWithScripta({
  isSmall,
  onExploreSavingsNow,
}: {
  isSmall: boolean
  onExploreSavingsNow: () => void
}) {
  const { COLORS } = useSColors()
  return (
    <Grid
      container
      sx={{
        zIndex: 1,
        backgroundColor: '#FFF',
        borderRadius: '20px',
        paddingRight: '20px',
        paddingLeft: '20px',
        paddingTop: '40px',
        // paddingBottom: '20px',
        maxWidth: '1015px',
        margin: 'auto',
        marginTop: '80px',
      }}
    >
      <Grid item xs={12} md={4} sx={{ textAlign: 'center' }}>
        <ThemedSVGRemote
          importUrl={talkToPharmacistImageSrc}
          width={310}
          height={190}
          alt="start saving with scripta"
        />
      </Grid>
      <Grid
        item
        xs={12}
        md={8}
        sx={{
          paddingLeft: isSmall ? '10px' : '30px',
          paddingRight: isSmall ? '10px' : '30px',
          alignSelf: 'flex-start',
        }}
      >
        <Grid item xs={12}>
          <STypography
            variant={isSmall ? 'title3_bold' : 'title2_bold'}
            color={COLORS.PRIMARY_BRAND}
          >
            Start Saving on Medications with Scripta!
          </STypography>
        </Grid>
        <Grid item xs={12} sx={{ mt: 2, marginBottom: '20px' }}>
          <STypography variant="bodybig_regular">
            Check out your personalized savings report to see your current
            medications and discover potential savings. Choose the best option
            for your needs through a simple guided process. We'll provide a
            summary and clear steps to make your savings a reality.
          </STypography>
        </Grid>
        <Grid item xs={12} sx={{ mt: 2, marginBottom: '30px' }}>
          <SButton
            noCaps
            data-testid="register-view-my-savings-button"
            onClick={() => {
              if (onExploreSavingsNow) {
                onExploreSavingsNow()
              }
            }}
          >
            Explore Your Savings!
          </SButton>
        </Grid>
      </Grid>
    </Grid>
  )
}

interface InfoBoxProps {
  image: string
  title: string
  text: string
  isSmall: boolean
}

function InfoBox({ image, title, text, isSmall }: InfoBoxProps) {
  const { COLORS } = useSColors()

  return (
    <Box
      sx={{
        backgroundColor: '#fff',
        borderRadius: '32px',
        padding: '24px',
        textAlign: 'center',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        position: 'relative',
        pt: '80px', // Space for the image
        border: `1px solid ${COLORS.PRIMARY_40}`,
        boxShadow: `0px 4px 8px 0px ${alpha(COLORS.PRIMARY_BRAND, 0.1)}`,
      }}
    >
      <Box
        sx={{
          position: 'absolute',
          top: '-130px',
          left: '50%',
          transform: 'translateX(-50%)',
          marginLeft: '15px',
        }}
      >
        <ThemedSVGRemote
          importUrl={image}
          width={167}
          height={200}
          alt={title}
        />
      </Box>
      <STypography variant="title3_bold" color={COLORS.PRIMARY_BRAND}>
        {title}
      </STypography>
      <STypography variant="bodybig_regular">{text}</STypography>
    </Box>
  )
}

function ProblemAndSolution({ isSmall }: { isSmall: boolean }) {
  return (
    <Box sx={{ maxWidth: '1100px', margin: 'auto', padding: '40px 20px' }}>
      <Grid
        sx={{ marginTop: '30px' }}
        container
        spacing={2}
        alignItems="center"
        justifyContent="center"
      >
        {/* Left half - Problem and Solution */}
        <Grid
          item
          xs={12}
          md={7}
          container
          spacing={isSmall ? undefined : 2}
          sx={{ position: 'relative' }}
        >
          <Grid
            item
            xs={12}
            md={6}
            sx={{
              paddingLeft: isSmall ? '40px' : '0px',
              paddingRight: isSmall ? '40px' : '0px',
            }}
          >
            <InfoBox
              image={doctorPriceSrc}
              title="Problem"
              text="When your doctor writes a script, they often don't know the cost of the drug."
              isSmall={isSmall}
            />
          </Grid>

          {/* Vertical arrow for mobile, Horizontal arrow for desktop */}
          {isSmall ? (
            <Box
              sx={{
                position: 'absolute',
                // left: '',
                right: '0',
                top: '50%',
                transform: 'translateX(-50%)',
                zIndex: 1,
                width: '30px',
                height: 'auto',
                // paddingTop: '100px',
                marginTop: '-40px', // Adjust this value to fine-tune vertical position
              }}
            >
              <img
                src={arrowVerticalSrc}
                width="100%"
                height="auto"
                alt="arrow"
              />
            </Box>
          ) : (
            <Box
              sx={{
                position: 'absolute',
                left: '50%',
                top: '-10%',
                transform: 'translateX(-30%)',
                zIndex: 1,
                width: '100px',
                display: { xs: 'none', md: 'block' },
              }}
            >
              <img
                src={arrowHorizontalSrc}
                width="100%"
                height="auto"
                alt="arrow"
              />
            </Box>
          )}

          <Grid
            item
            xs={12}
            md={6}
            sx={{
              paddingTop: isSmall ? '120px' : '0px',
              paddingLeft: isSmall ? '40px' : '0px',
              paddingRight: isSmall ? '40px' : '0px',
            }}
          >
            <InfoBox
              image={piggyBankSrc}
              title="Solution"
              text="We deliver Savings Reports with lower-priced options based on your health plan."
              isSmall={isSmall}
            />
          </Grid>
        </Grid>

        {/* Right half - Savings Report Example */}
        <Grid item xs={12} md={5}>
          <img
            src={savingsReportExampleSrc}
            width={isSmall ? '100%' : '100%'}
            height="auto"
            alt="savings report example"
            style={{
              maxWidth: '500px',
              margin: 'auto',
              display: 'block',
            }}
          />
        </Grid>
      </Grid>
    </Box>
  )
}

function WelcomeFooter() {
  const { COLORS } = useSColors()
  const theme = useTheme()
  const isSmall = useMediaQuery(theme.breakpoints.down('sm'))

  return (
    <Box
      sx={{
        backgroundColor: '#fff',
        padding: { xs: '20px', md: '50px' },
      }}
    >
      <Grid container spacing={3} alignItems="center">
        {/* Logo Column */}
        <Grid item xs={12} md={2}>
          <Box
            sx={{
              display: 'flex',
              justifyContent: { xs: 'flex-start', md: 'flex-start' },
            }}
          >
            <img
              src={theme.customized.darkOnWhiteLogoUrl}
              alt="Scripta Logo"
              style={{ height: '40px' }}
            />
          </Box>
        </Grid>

        {/* Text Column */}
        <Grid item xs={12} md={7}>
          <Box sx={{ textAlign: { xs: 'left', md: 'left' } }}>
            <STypography variant="title3_bold" color={COLORS.PRIMARY_BRAND}>
              Want Your Company to Offer Scripta?
            </STypography>
            <STypography variant="body_regular">
              Ask your HR department to add Scripta to your company's health
              benefits plan.
            </STypography>
          </Box>
        </Grid>

        {/* Button Column */}
        <Grid item xs={12} md={3}>
          <Box
            sx={{
              display: 'flex',
              justifyContent: { xs: 'flex-start', md: 'flex-end' },
            }}
          >
            <SButton
              variant="contained"
              noCaps
              sx={{
                backgroundColor: '#4285F4',
                color: '#fff',
                '&:hover': {
                  backgroundColor: '#3367D6',
                },
              }}
              onClick={() =>
                (window.location.href = 'mailto:help@scriptainsights.com')
              }
            >
              Contact us
            </SButton>
          </Box>
        </Grid>
      </Grid>
    </Box>
  )
}

function HomeButton({
  isSmall,
  onHomeClicked,
  onScrolledOutOfSight,
  mode = 'prereg',
}: {
  isSmall: boolean
  onHomeClicked: () => void
  onScrolledOutOfSight: () => void
  mode?: 'register' | 'prereg'
}) {
  const intersectionRef = React.useRef(null)
  const intersection = useIntersection(intersectionRef, {
    root: null,
    rootMargin: '0px',
    threshold: 1,
  })
  const { mobileVersion, setMobileVersion } = useMemberAppContext()
  const history = useHistory()
  const { t } = useSTranslate('common')

  const buttonText = mode === 'prereg' ? t('backToHome') : t('viewMySavings')

  useEffect(() => {
    if (intersection && intersection.isIntersecting === false) {
      onScrolledOutOfSight()
    }
  }, [intersection])

  const goToHome = () => {
    onHomeClicked()
  }
  return (
    <div ref={intersectionRef}>
      <WhiteActionButton
        dataTestId="preregister-go-home-button"
        isSmall={isSmall}
        onClick={goToHome}
        buttonChildeCustomNodeElement={
          <>
            {buttonText}
            {/* {isSmall ? <HomeIcon /> : buttonText} */}
            {mode === 'register' && <ExitToAppIcon style={{ marginLeft: 5 }} />}
          </>
        }
      />
    </div>
  )
}
function WhiteActionButton({
  dataTestId,
  buttonText,
  buttonChildeCustomNodeElement,
  onClick,
  isSmall,
}: {
  dataTestId: string
  buttonText?: string
  buttonChildeCustomNodeElement?: any
  onClick: () => void
  isSmall: boolean
}) {
  const { COLORS } = useSColors()
  return (
    <SButton
      data-testid={dataTestId}
      variant="outlined"
      onClick={onClick}
      noCaps
      sx={{
        // height: isSmall ? '28px' : '37px',
        padding: `${SPACING._025} ${SPACING._1} ${SPACING._025} ${SPACING._1}`,
        // padding: isSmall
        //   ? '10px 20px'
        //   : `${SPACING._025} ${SPACING._1} ${SPACING._025} ${SPACING._1}`,
        borderRadius: '8px',
        color: COLORS.PRIMARY_BRAND,
        fontSize: '13px',
        borderColor: 'white',
        backgroundColor: COLORS.PRIMARY_10,
        '&:hover': {
          backgroundColor: 'white',
          color: COLORS.PRIMARY_BRAND,
        },
      }}
    >
      {buttonChildeCustomNodeElement
        ? buttonChildeCustomNodeElement
        : buttonText}
    </SButton>
  )
}

function MeetScripta({ isSmall }: { isSmall: boolean }) {
  return (
    <Grid
      container
      sx={{
        marginTop: '0px',
        paddingLeft: isSmall ? '10px' : '0px',
        paddingRight: isSmall ? '10px' : '0px',
        maxWidth: '1015px',
        margin: 'auto',
      }}
      alignItems="center"
    >
      <Grid
        item
        xs={12}
        md={5}
        sx={{
          marginTop: '0px',
          display: 'flex',
          justifyContent: 'center',
          paddingRight: isSmall ? '0px' : '20px',
        }}
      >
        <ThemedSVGRemote
          importUrl={feedbackImgSrc}
          width={isSmall ? 275 : 325}
          height={isSmall ? 275 : 325}
          alt="feedback"
        />
      </Grid>
      <Grid item xs={12} md={7}>
        <Box>
          <STypography
            variant="bodybig_regular"
            sx={{
              textAlign: 'left',
              padding: isSmall ? '20px' : '0px',
            }}
          >
            Scripta is a FREE member benefit offered through your health plan
            that could save you hundreds or even thousands of dollars each year
            on your prescriptions.
          </STypography>
          <STypography
            variant="bodybig_regular"
            sx={{
              marginTop: '20px',
              textAlign: 'left',
              padding: isSmall ? '0px 20px 20px 20px' : '0px',
            }}
          >
            At Scripta, we know medications can be expensive. Our job is to help
            you better afford your medicines, so you can focus on staying
            healthy, while keeping the most money in your pocket.
          </STypography>
        </Box>
      </Grid>
    </Grid>
  )
}

function HowToSaveWithScripta({ isSmall }: { isSmall: boolean }) {
  const { COLORS } = useSColors()
  return (
    <Grid
      container
      sx={{
        position: 'relative',
        padding: isSmall ? '10px' : '20px',
      }}
    >
      <Box
        sx={{
          position: 'absolute',
          marginRight: isSmall ? 0 : '40px',
          left: isSmall ? 0 : '25%',
          right: '0',
          top: '-20px', // Starts 100px above
          height: 'calc(100% + 40px)', // Extends 100px above and below
          bgcolor: COLORS.PRIMARY_10,
          borderRadius: '20px',
          zIndex: 0, // Puts it behind content
        }}
      />
      <Grid
        item
        md={6}
        xs={12}
        sx={{
          zIndex: 1,
          // border: '1px solid blue',
        }}
      >
        <HowToSaveVideo isSmall={isSmall} />
      </Grid>
      <Grid
        item
        md={6}
        xs={12}
        sx={{
          zIndex: 1,
          // border: '1px solid blue'
        }}
      >
        <STypography
          variant="title2_bold"
          sx={{
            color: COLORS.PRIMARY_BRAND,
            marginBottom: '20px',
            paddingLeft: isSmall ? '10px' : '40px',
            paddingRight: isSmall ? '10px' : '40px',
          }}
        >
          How to save with Scripta
        </STypography>
        <Grid
          container
          spacing={2}
          sx={{
            maxWidth: '650px',
            paddingLeft: isSmall ? '10px' : '40px',
            paddingRight: isSmall ? '10px' : '40px',
            // bgcolor: 'rgba(0,0,255,0.1)', // Debug color
          }}
        >
          <Grid item xs={12}>
            <Box
              sx={{
                display: 'flex',
                // backgroundColor: 'red',

                justifyContent: 'center',
              }}
            >
              <StepCard
                step={1}
                customStepContent={
                  <>
                    <STypography variant="body_regular">
                      <span style={{ fontWeight: 'bold' }}>
                        Download our app
                      </span>{' '}
                      or visit{' '}
                      <Link
                        href="https://members.scriptainsights.com"
                        target="_blank"
                        rel="noopener noreferrer"
                        sx={{
                          textDecoration: 'underline',
                          color: COLORS.PRIMARY_BRAND,
                        }}
                      >
                        members.scriptainsights.com
                      </Link>{' '}
                      to see your full report.
                    </STypography>
                  </>
                }
                customBackgroundColor="#fff"
              />
            </Box>
          </Grid>
          <Grid item xs={12}>
            <StepCard
              step={2}
              customStepContent={
                <STypography variant="body_regular">
                  Get your
                  <span
                    style={{
                      fontWeight: 'bold',
                      paddingLeft: '5px',
                      marginRight: '5px',
                    }}
                  >
                    in-app exclusive savings!
                  </span>
                  Or{' '}
                  <span style={{ fontWeight: 'bold' }}>
                    discuss a switch with your doctor{' '}
                  </span>{' '}
                  or pharmacist.
                </STypography>
              }
              customBackgroundColor="#fff"
            />
          </Grid>
          <Grid item xs={12}>
            <StepCard
              step={3}
              stepDescription="Fill at your suggested pharmacy."
              customBackgroundColor="#fff"
            />
          </Grid>
          <Grid item xs={12} sx={{ mt: '10px' }}>
            <STypography
              variant="body_regular"
              sx={{ color: COLORS.PRIMARY_BRAND }}
            >
              Remember, always talk to your doctor before making changes to your
              current medications or taking any new ones. Only your doctor can
              determine the medications that are right for you.
            </STypography>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}

function HowToSaveVideo({ isSmall }: { isSmall: boolean }) {
  const isVerticalLayout = useMediaQuery('(min-width:1195px)')

  return (
    <Box
      sx={{
        overflow: 'hidden',
        marginTop: '5px',
        padding: isVerticalLayout ? '10px' : '0px',
        height: '100%',
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        minHeight: isVerticalLayout ? '400px' : 'auto',
      }}
    >
      <VideoWithPoster
        isSmallScreen={false}
        title="How To Save With Scripta"
        youtubeId="Ejp8rOJDH5s"
      />
    </Box>
  )
}
