import { Box, Grid, Typography } from '@mui/material'
import { EmblaCarouselType, EmblaOptionsType } from 'embla-carousel'
import useEmblaCarousel from 'embla-carousel-react'
import { useCallback, useEffect, useState } from 'react'
import { SPACING } from 'src/app/system/theme2'

import { useSTranlateForObjects_waitUntilReady } from 'src/app/hooks/useSTranslate'
import { LOG } from 'src/app/v2/applog'
import {
  SavingStrategy,
  getAllStrategiesExceptUnknown,
} from '../../strategies/types'
import { VideoWithPoster } from '../../strategies/VideoWithPoster'
import './horizontal.css'
import './vertical.css'
import { SArrowButton } from 'src/app/system/customcomponents/SArrowButtons'

export function StrategyCarousel({
  direction,
  onSlideClick,
}: {
  direction: 'horizontal' | 'vertical'
  onSlideClick: ({
    strategy,
    youTubeId,
  }: {
    strategy: SavingStrategy
    youTubeId: string
  }) => void
}) {
  const [selectedIndex, setSelectedIndex] = useState(0)
  const [scrollSnaps, setScrollSnaps] = useState<number[]>([])
  const [emblaRef, emblaApi] = useEmblaCarousel({
    align: 'start',
    containScroll: 'trimSnaps',
    dragFree: true,
    slidesToScroll: 1,
    axis: direction === 'horizontal' ? 'x' : 'y',
  })

  const [prevBtnDisabled, setPrevBtnDisabled] = useState(true)
  const [nextBtnDisabled, setNextBtnDisabled] = useState(true)

  const onPrevButtonClick = useCallback(() => {
    if (!emblaApi) return
    emblaApi.scrollPrev()
  }, [emblaApi])

  const onNextButtonClick = useCallback(() => {
    if (!emblaApi) return
    emblaApi.scrollNext()
  }, [emblaApi])

  const onSelect = useCallback((emblaApi: EmblaCarouselType) => {
    setPrevBtnDisabled(!emblaApi.canScrollPrev())
    setNextBtnDisabled(!emblaApi.canScrollNext())
  }, [])

  useEffect(() => {
    if (!emblaApi) return

    onSelect(emblaApi)
    emblaApi.on('reInit', onSelect).on('select', onSelect)
  }, [emblaApi, onSelect])

  const allPossibleStrategies = getAllStrategiesExceptUnknown()
  //filter out $4/$9
  const strategies = allPossibleStrategies
    .filter((s) => s !== SavingStrategy.$4$9Program)
    .filter((s) => s !== SavingStrategy.Coupons)
    .filter((s) => s !== SavingStrategy.PharmacySwitch)

  if (direction === 'vertical') {
    return (
      <section className={`embla_vertical`}>
        <Grid container spacing={SPACING._1}>
          <Grid item xs="auto">
            <Grid
              container
              direction="column"
              gap={SPACING._1}
              sx={{
                height: '440px',
                maxHeight: '440px',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <Grid item>
                <SArrowButton
                  direction="up"
                  onClick={onPrevButtonClick}
                  disabled={prevBtnDisabled}
                />
              </Grid>
              <Grid item>
                <SArrowButton
                  direction="down"
                  onClick={onNextButtonClick}
                  disabled={nextBtnDisabled}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs>
            <div className={`embla__viewport_${direction}`} ref={emblaRef}>
              <div className={`embla__container_${direction}`}>
                {strategies.map((s, index) => (
                  <div className={`embla__slide_${direction}`} key={index}>
                    <StrategySlide
                      strategy={s}
                      direction={direction}
                      onClick={(s) => {
                        onSlideClick(s)
                      }}
                    />
                  </div>
                ))}
              </div>
            </div>
          </Grid>
        </Grid>
      </section>
    )
  }

  //horizontal
  return (
    <section className={`embla_horizontal`}>
      <div className={`embla__viewport_horizontal`} ref={emblaRef}>
        <div className={`embla__container_horizontal`}>
          {strategies.map((s, index) => (
            <div className={`embla__slide_${direction}`} key={index}>
              <StrategySlide
                strategy={s}
                direction={direction}
                onClick={onSlideClick}
              />
            </div>
          ))}
        </div>
      </div>

      <Grid
        container
        justifyContent="center"
        alignItems="center"
        gap={SPACING._1}
        sx={{ mt: SPACING._1 }}
      >
        <Grid item>
          <SArrowButton
            direction="left"
            onClick={onPrevButtonClick}
            disabled={prevBtnDisabled}
          />
        </Grid>
        <Grid item>
          <SArrowButton
            direction="right"
            onClick={onNextButtonClick}
            disabled={nextBtnDisabled}
          />
        </Grid>
      </Grid>
    </section>
  )
}

function StrategySlide({
  strategy,
  direction,
  onClick,
}: {
  strategy: SavingStrategy
  direction: 'horizontal' | 'vertical'
  onClick: ({
    strategy,
    youTubeId,
  }: {
    strategy: SavingStrategy
    youTubeId: string
  }) => void
}) {
  const { t, ready } = useSTranlateForObjects_waitUntilReady('strategies')
  if (!ready) {
    return null
  }
  let strategiesRoot: any = t(strategy, {
    returnObjects: true,
  })

  let strategyConfig = strategiesRoot['scripta']
  if (!strategyConfig) {
    LOG.error(
      'generic_error',
      'strategy config not found for strategy: ',
      strategy,
    )
    return null
  }

  return (
    <Box className={`embla__slide_${direction}`}>
      {strategyConfig && strategyConfig.youtubeId ? (
        <Grid
          item
          sx={
            {
              // borderRadius: RADIUS.sm,
              // overflow: 'hidden',
              // marginTop: SPACING._1,
            }
          }
          style={{ maxWidth: '213px', width: '100%' }}
        >
          <VideoWithPoster
            customOnVideoClick={() =>
              onClick({ strategy, youTubeId: strategyConfig.youtubeId })
            }
            isSmallScreen={true}
            title={strategy}
            youtubeId={strategyConfig.youtubeId}
          />
        </Grid>
      ) : null}
    </Box>
  )
}
