import React from 'react'
import { Route, Switch, useHistory, useRouteMatch } from 'react-router-dom'
// import { CreateProfile } from './CreateProfile'
import { RegistrationErrorHandler } from './RegistrationErrorHandler'
import { FindTenantComponent } from './FindTenantComponent'
import { LoginOrRegister } from './LoginOrRegister'
import { NewTenantAccount } from './NewTenantAccount'
import { useRegistration } from './RegistrationContext'
import { ForgotUsername } from './ForgotUsername'
import { ForgotPassword } from './ForgotPassword'
import { ResetPassword } from './ResetPassword'
import { GenericErrorPage } from '../application/GenericErrorPage'
import { FindAccountFromQuickLink } from './FindAccountFromQuickLink'
import { PreReg } from './PreReg'
import { SsoCompleteFirstTimeAccessForm } from '../sso/SsoCompleteFirstTimeAccessForm'
import { ROUTE_SAML_LOGIN, ROUTE_SAML_LOGIN_ERROR } from '../PortalRoutes'
import { SamlLoginHandler } from '../sso/SamlLoginHandler'
import SamlLoginError from '../sso/SamlLoginError'
import { CompletePreregFirstTimeProfile } from './CompletePreregFirstTimeProfile'
import { IdentityValidationEKSSubflow } from 'src/app/registration/IdentityValidationEKS'
import { ConfirmOtpEKS } from 'src/app/registration/ConfirmOtpEKS'
import { RegChallengeQuestionsEKS } from 'src/app/registration/RegChallengeQuestionsEKS'
import { EditContactInfoEKS } from 'src/app/registration/EditContactInfoEKS'
import { CreateProfileEKS } from 'src/app/registration/CreateProfileEKS'
import { UnsubscribeFromNotifications } from 'src/app/application/Unsubscribe'
import { StrategyCarousel } from 'src/app/system/strategycarousel/StrategyCarousel'
import { WelcomePreregDialog } from 'src/app/registration/WelcomePreregDialog'

export const REG_ROUTE_ROOT = '/register'
export const REG_ROUTE_LOGIN = '/register/login'

export const REG_ROUTE_UNSUBSCRIBE = '/register/unsubscribe'

export const REG_ROUTE_FORGOT_USERNAME = '/register/forgotusername'
export const REG_ROUTE_FORGOT_PASSWORD = '/register/forgotpassword'

export const REG_ROUTE_PREREGISTER = '/preregister'

//for the first SSO users, send here first to complete registration
export const REG_ROUTE_REGISTER_SSO_COMPLETEPROFILE = '/ssoregister'

//some partner specific subroutes, i.e. hcbb logout.
//it is mostly here not to even show the intermediate window that would otherwise be triggerd
//during cusotm logout comonent (I.e. defaults cripta username/password)
export const REG_ROUTER_PARTNER_GENERIC_LOGOUT = '/partner/logout'

//the first is inteliggent error handler that deals with known error response codes
//statuses and handle showing the api response message and provides a "Try Again" logic
export const REG_ROUTE_ERROR_HANDLER = '/register/error'
//in case of any additoinal server errors during registration, we will redirect to this page
//(which for now just shows our standard generic error)
export const REG_ROUTE_SERVER_ERROR = '/register/serverror'
export const REG_ROUTE_FIND_TENANT = '/register/findtenant'
export const REG_ROUTE_FIND_TENANT_MEMBER_FROM_QR = '/register/qr'

export const REG_ROUTE_REGISTER_TENANT_ACCOUNT = '/register/newtenantaccount'
//new identity validation for EKS
//need to keep the old one bc its used by prreg and quicklink reg
//so untill those apis are migrated we will have a dedicated EKS identity validation route for registration flows
export const REG_ROUTE_IDENTITY_VALIDATION_EKS =
  '/register/identityvalidationeks'
export const REG_ROUTE_VALIDATE_OTP_EKS = '/register/validateotpeks'

export const REG_ROUTE_RESET_PASSWORD = '/register/resetpwd'

export const REG_ROUTE_ANSWER_QUESTIONS_EKS = '/register/answerquestionseks'

export const REG_ROUTE_EDIT_CONTACT_INFO = '/register/contactinfo'
export const REG_ROUTE_EDIT_CONTACT_INFO_EKS = '/register/contactinfoeks'

//TODO - this is removed, consolidated to contactinfo
// export const REG_ROUTE_EDIT_SMS_OPT_IN = '/register/smsoptin'
export const REG_ROUTE_CREATE_PROFILE_EKS = '/register/createprofileeks'
export const REG_ROUTE_COMPLETE_PREREG_PROFILE = '/register/completeprereg'

export function RegistrationSubRoutes() {
  return (
    <Switch>
      <Route exact path={REG_ROUTE_ROOT}>
        <LoginOrRegister />
      </Route>

      <Route exact path={REG_ROUTE_LOGIN}>
        <LoginOrRegister />
        {/* <IdentityValidationSubflow
          emailMasked="mir**@scriptainsights.com"
          phoneMasked="1234*****"
        /> */}
        {/* <WelcomePreregDialog
          onGoHomeFromThankYouClicked={() => {}}
          mode="register"
        /> */}
      </Route>

      <Route path={REG_ROUTE_PREREGISTER} exact>
        <PreReg />
      </Route>

      {/* initializes the sso member profile */}
      <Route path={ROUTE_SAML_LOGIN}>
        <SamlLoginHandler />
      </Route>

      <Route path={ROUTE_SAML_LOGIN_ERROR}>
        <SamlLoginError />
      </Route>

      {/* if sso users needs to sign up */}
      <Route path={REG_ROUTE_REGISTER_SSO_COMPLETEPROFILE} exact>
        <SsoCompleteFirstTimeAccessForm />
      </Route>

      <Route exact path={REG_ROUTE_FORGOT_USERNAME}>
        <ForgotUsername />
      </Route>
      <Route exact path={REG_ROUTE_FORGOT_PASSWORD}>
        <ForgotPassword />
      </Route>
      <Route exact path={REG_ROUTE_FIND_TENANT}>
        <FindTenantComponent />
      </Route>
      <Route exact path={REG_ROUTE_FIND_TENANT_MEMBER_FROM_QR}>
        <FindAccountFromQuickLink />
      </Route>

      <Route exact path={REG_ROUTE_IDENTITY_VALIDATION_EKS}>
        <IdentityValidationEKSSubflow />
      </Route>

      <Route exact path={REG_ROUTE_VALIDATE_OTP_EKS}>
        <ConfirmOtpEKS />
      </Route>
      <Route exact path={REG_ROUTE_RESET_PASSWORD}>
        <ResetPassword />
      </Route>

      <Route exact path={REG_ROUTE_ANSWER_QUESTIONS_EKS}>
        <RegChallengeQuestionsEKS />
      </Route>
      {/* <Route exact path={REG_ROUTE_EDIT_CONTACT_INFO}>
        <EditContactInfo />
      </Route> */}
      <Route exact path={REG_ROUTE_EDIT_CONTACT_INFO_EKS}>
        <EditContactInfoEKS />
      </Route>
      {/* <Route exact path={REG_ROUTE_EDIT_SMS_OPT_IN}>
        <SmsOptInRegistration />
      </Route> */}
      {/* <Route exact path={REG_ROUTE_CREATE_PROFILE}>
        <CreateProfile />
      </Route> */}
      <Route exact path={REG_ROUTE_CREATE_PROFILE_EKS}>
        <CreateProfileEKS />
      </Route>
      {/* when a preregistered member verifies their identitiy upon first login */}
      <Route exact path={REG_ROUTE_COMPLETE_PREREG_PROFILE}>
        <CompletePreregFirstTimeProfile />
      </Route>

      <Route exact path={REG_ROUTE_ERROR_HANDLER}>
        <RegistrationErrorHandler />
      </Route>
      <Route exact path={REG_ROUTE_REGISTER_TENANT_ACCOUNT}>
        <NewTenantAccount />
      </Route>
      <Route exact path={REG_ROUTE_SERVER_ERROR}>
        <GenericErrorPage />
      </Route>
      <Route path={REG_ROUTER_PARTNER_GENERIC_LOGOUT}>
        <div>PARTNER LOGOUT</div>
      </Route>

      <Route exact path={REG_ROUTE_UNSUBSCRIBE}>
        <UnsubscribeFromNotifications />
      </Route>
    </Switch>
  )
}
// function NoMatch() {
//
//   return <div>Loadding...</div>
// }
