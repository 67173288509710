import { MemberSavingReport } from '../../types'
import {
  URL_PARAM_PREREGISTRATION_CODE,
  URL_PARAM_QUICKLINK_CODE,
} from '../app-root-types'
import { REG_ROUTE_REGISTER_SSO_COMPLETEPROFILE } from '../registration/RegistrationSubRoutes'

export function IS_DEV() {
  // return false
  if (process.env.NODE_ENV === 'development') {
    return true
  } else return false
}

export function getVersion() {
  //so we may not need to pass the custom isNativeApp flag
  if ((window as any).ReactNativeWebView || (window as any).version)
    return (window as any).versiontrue
}

export function IS_NATIVE() {
  //even just the presence of ReactNativeWebView on the window objct should be enough
  //so we may not need to pass the custom isNativeApp flag
  if ((window as any).ReactNativeWebView || (window as any).isNativeApp)
    return true
  else return false
}

export function getOS() {
  var uA = navigator.userAgent || navigator.vendor || (window as any).opera
  if (
    (/iPad|iPhone|iPod/.test(uA) && !(window as any).MSStream) ||
    (uA.includes('Mac') && 'ontouchend' in document)
  )
    return 'iOS'

  var i,
    os = ['Windows', 'Android', 'Unix', 'Mac', 'Linux', 'BlackBerry']
  for (i = 0; i < os.length; i++)
    if (new RegExp(os[i], 'i').test(uA)) return os[i]
}

export function isDesktop() {
  var uA = navigator.userAgent || navigator.vendor || (window as any).opera

  // Check for mobile and tablet user agents
  if (
    (/iPad|iPhone|iPod/.test(uA) && !(window as any).MSStream) ||
    /Android/.test(uA) ||
    (uA.includes('Mac') && 'ontouchend' in document)
  ) {
    return false
  }

  return true
}

export function isAndroid(): boolean {
  return getOS() === 'Android'
}

export function isIOS(): boolean {
  return getOS() === 'iOS'
}

export function isSafari(): boolean {
  return /^((?!chrome|android).)*safari/i.test(navigator.userAgent)
}

export interface NativeMessage {
  actionName:
    | 'member_authenticated'
    | 'download_report'
    | 'exit_and_close_app'
    | 'app_logout'
    | 'set_mobile_ui_state'
    | 'call_number'
    | 'open_link'
    | 'download_coupon'
    | 'can_go_back_state'
    | 'set_status_bar_color_override' 
    | 'app_ready'
  actionJwt?: string
  actionExtra?: any
}
export function nativeApi_setBackButtonVisible(visible: boolean) {
  const msg: NativeMessage = {
    actionName: 'set_mobile_ui_state',
    actionJwt: undefined,
    actionExtra: {
      isBackButtonVisble: visible,
    },
  }

  nativeApi_sendMessage(msg)

  return
}

export function nativeApi_setCanGoBack(canGoBack: boolean) {
  const msg: NativeMessage = {
    actionName: 'can_go_back_state',
    actionJwt: undefined,
    actionExtra: {
      canGoBack,
    },
  }

  nativeApi_sendMessage(msg)

  return
}

export function nativeApi_callNumber(number: string) {
  var digitsOnly = number.replace(/\D/g, '')
  const msg: NativeMessage = {
    actionName: 'call_number',
    actionJwt: undefined,
    actionExtra: {
      number_value: digitsOnly,
    },
  }

  nativeApi_sendMessage(msg)

  return
}

export function nativeApi_openExternalUrl(url: string) {
  const msg: NativeMessage = {
    actionName: 'open_link',
    actionJwt: undefined,
    actionExtra: {
      url: url,
    },
  }

  nativeApi_sendMessage(msg)

  return
}

export function nativeApi_sendMessage(msg: NativeMessage) {
  if (IS_NATIVE()) {
    if ((window as any).ReactNativeWebView) {
      ;(window as any).ReactNativeWebView.postMessage(JSON.stringify(msg))
    } else {
      console.warn(
        'Got a send message to native, but no native environment detected',
      )
    }

    return
  } else {
    console.warn(
      'Received a native message, but not in native context. Message will be ignored.',
    )
  }
}

export function nativeApi_sendLogoutMessageToNative() {
  const logoutmsg: NativeMessage = {
    actionName: 'app_logout',
  }
  nativeApi_sendMessage(logoutmsg)
}

export function nativeApi_setStatusBarBackgroundOverride(
  color: 'white' | undefined,
) {
  const msg: NativeMessage = {
    actionName: 'set_status_bar_color_override',
    actionExtra: {
      color,
    },
  }

  nativeApi_sendMessage(msg)

  return
}

export function nativeApi_appReady() {
  const msg: NativeMessage = {
    actionName: 'app_ready',
    actionExtra: {},
  }

  nativeApi_sendMessage(msg)

  return
}

export function toPascalCase(word: string): string {
  if (!word) return ''

  return word.replace(/(\w)(\w*)/g, function (g0, g1, g2) {
    return g1.toUpperCase() + g2.toLowerCase()
  })
}

//clean-up and standaridze all the URL naming
//point directly to proper endpoints as opposed to various 53 redirects
//fail in case we cannot properly determine if its DEV/INT/PROD as opposed to defaulting
const API_SERVICES_DEV =
  'http://localhost:8082/scripta-insights/member-app-api/0.1'

//TODO - this needs some better research
const API_SERVICES_DEV_VIA_NATIVEIP4 =
  'https://member-app-services-int-pipeline.scriptainsights.io/scripta-insights/member-app-api/0.1'

// const API_SERVICES_INT_PIPELINE =
//   'https://member-app-services-int-pipeline.scriptainsights.io/scripta-insights/member-app-api/0.1'
//
//switch to graviton processor ebs backend
const API_SERVICES_INT_PIPELINE =
  'https://grav-int-member-app-services.scriptainsights.com/scripta-insights/member-app-api/0.1'

// const API_SERVICES_BLUE_PREPROD =
//   'https://member-app-services-blue-pipeline.scriptainsights.io/scripta-insights/member-app-api/0.1'

const API_SERVICES_BLUE_PREPROD =
  'https://grav-blue-member-app-services-pipeline.scriptainsights.io/scripta-insights/member-app-api/0.1'

const API_SERVICES_PROD =
  'https://member-app-services.scriptainsights.io/scripta-insights/member-app-api/0.1'

// const API_SERVICES_GRAV_PROD =
//   'https://grav-green-member-app-services.scriptainsights.io/scripta-insights/member-app-api/0.1'
//new .com domain
const API_SERVICES_GRAV_PROD =
  'https://grav-green-member-app-services.scriptainsights.com/scripta-insights/member-app-api/0.1'

const API_SERVICES_DR_BACKUP =
  'https://member-services-backup.scriptainsights.io/scripta-insights/member-app-api/0.1'

const MICROSERVICES_API_MEMBER_URL_INT =
  'https://member-int.scriptainsights.com'

// const MICROSERVICES_API_MEMBER_URL_PROD = 'https://member.scriptainsights.io'
// new .com domain
const MICROSERVICES_API_MEMBER_URL_PROD = 'https://member.scriptainsights.com'

const MICROSERVICES_API_MEMBER_BLUE_PREPROD =
  'https://member-blue.scriptainsights.io'

const MICROSERVICES_API_MEMBER_URL_DR = 'https://member-dr.scriptainsights.io'

//for now dev also hits integration services, unable to run locally
const MICROSERVICES_API_MEMBER_URL_DEV = 'https://member-int.scriptainsights.io'

export function getPortalApiRootUrl() {
  if (IS_DEV()) {
    if (IS_NATIVE()) {
      return API_SERVICES_DEV_VIA_NATIVEIP4
    } else return API_SERVICES_INT_PIPELINE
    // } else return API_SERVICES_BLUE_PREPROD
  } else {
    if (IS_INTEGRATION()) {
      return API_SERVICES_INT_PIPELINE
    } else if (IS_BLUE_PROD()) {
      return API_SERVICES_BLUE_PREPROD
    } else if (IS_PROD()) {
      return API_SERVICES_GRAV_PROD
      // return API_SERVICES_PROD
    } else if (IS_DR_BACKUP()) {
      return API_SERVICES_DR_BACKUP
    } else
      throw new Error(
        'Unable to determine proper environment details based on currently URL of the app.',
      )
  }
}

export function resolveMicroServerRoot() {
  //TODO - other environments if we
  if (IS_DEV()) {
    return MICROSERVICES_API_MEMBER_URL_DEV
    // return MICROSERVICES_API_MEMBER_BLUE_PREPROD
  } else if (IS_INTEGRATION()) {
    return MICROSERVICES_API_MEMBER_URL_INT
  } else if (IS_BLUE_PROD()) {
    return MICROSERVICES_API_MEMBER_BLUE_PREPROD
  } else if (IS_PROD()) {
    return MICROSERVICES_API_MEMBER_URL_PROD
  } else if (IS_DR_BACKUP()) {
    return MICROSERVICES_API_MEMBER_URL_DR
  } else
    throw new Error(
      'Microservice URLS are only available or integration at the moment. ',
    )
}
//we no longer default to prod, but instead expect very specific namings
//otherwise accidental branch will end up used as prod
export function IS_PROD() {
  const url = window.location.href
  //use a full url to determine prod just to be safe
  if (url && url.indexOf('https://members.scriptainsights.com') > -1)
    return true
  if (url && url.indexOf('https://members-new.scriptainsights.com') > -1)
    return true
  else return false
}

//new pre-prod aka blue environment
export function IS_BLUE_PROD() {
  const url = window.location.href
  //use a full url to determine prod just to be safe
  if (url && url.indexOf('https://members-blue.scriptainsights.io/') > -1)
    return true
  if (url && url.indexOf('https://new-members-blue.scriptainsights.io') > -1)
    return true
  else return false
}

//temporary until vercel can pass proper env variable to give us the branch name
export function IS_INTEGRATION() {
  const url = window.location.href
  //integration is aws amplify: integration.XXX.awsamplify.com
  if (url && url.indexOf('portal-int.') > -1) return true
  else if (url && url.indexOf('portal-poc.') > -1) return true
  else return false
}

export function IS_DR_BACKUP() {
  const url = window.location.href
  //integration is aws amplify: integration.XXX.awsamplify.com
  if (url && url.indexOf('https://new-members-dr.scriptainsights.io') > -1)
    return true
  else return false
}

export function roundAllSavingsAmountsToTwoDecimals(
  report: MemberSavingReport,
): MemberSavingReport {
  const roundedReport = report

  //ROUND UP
  if (
    roundedReport.memberPrescriptions &&
    roundedReport.memberPrescriptions.length > 0
  ) {
    roundedReport.memberPrescriptions.forEach((p) => {
      //ROUNDING UP ORIGINAL PRESCRIPTION DETAILS
      if (p.prescription) {
        if (p.prescription.avgCopay) {
          p.prescription.avgCopay = toRoundedNumber(p.prescription.avgCopay)
        }
        if (p.prescription.beforeDeductibleCost) {
          p.prescription.beforeDeductibleCost = toRoundedNumber(
            p.prescription.beforeDeductibleCost,
          )
        }

        if (p.prescription.afterDeductibleCost) {
          p.prescription.afterDeductibleCost = toRoundedNumber(
            p.prescription.afterDeductibleCost,
          )
        }
      }
      //ROUNDING UP CHEAPER ALTERNATIVE OPTION DETAILS
      if (p.alternatives && p.alternatives.length > 0) {
        p.alternatives.forEach((alt) => {
          if (alt.beforeDeductibleCost) {
            alt.beforeDeductibleCost = toRoundedNumber(alt.beforeDeductibleCost)
          }
          if (alt.beforeDeductibleSavings) {
            alt.beforeDeductibleSavings = toRoundedNumber(
              alt.beforeDeductibleSavings,
            )
          }
          if (alt.avgCopay) {
            alt.avgCopay = toRoundedNumber(alt.avgCopay)
          }

          if (alt.afterDeductibleCost) {
            alt.afterDeductibleCost = toRoundedNumber(alt.afterDeductibleCost)
          }
          if (alt.afterDeductibleSavings) {
            alt.afterDeductibleSavings = toRoundedNumber(
              alt.afterDeductibleSavings,
            )
          }
          if (alt.savings) {
            alt.savings = toRoundedNumber(alt.savings)
          }
        })
      }
    })
  }

  return roundedReport
}

export function toRoundedNumber(num: number): number {
  //dont blow up fatally if data coming in is not a number
  if (isNaN(num)) {
    console.warn(
      'Got a non-number value in savings report, unable to perform decimal rounding for value=',
      num + ',will return alue as is',
      'error',
    )
    return num
  }
  return +num.toFixed(2)
  // return parseFloat(num.toFixed(2))
}
export function getUrlParameter(name: string) {
  name = name.replace(/[\[]/, '\\[').replace(/[\]]/, '\\]')
  var regex = new RegExp('[\\?&]' + name + '=([^&#]*)')

  var results = regex.exec(window.location.href)
  let ret =
    results === null ? '' : decodeURIComponent(results[1].replace(/\+/g, ' '))
  if (isEmpty(ret)) {
    return undefined
  } else return ret
}

export function isQuickLinkRegistration(): boolean {
  if (
    window.location.href.indexOf(URL_PARAM_QUICKLINK_CODE) > -1 &&
    //quick link processing, sample url sent via marketing:
    //https://portal-int.scriptainsights.io/qlp?code=898c97953488493bb8474521205bee69
    window.location.href.indexOf('/qlp') > -1
  ) {
    return true
  } else return false
}
export function isSamlSsoLogin(): boolean {
  if (window.location.href.indexOf('saml') > -1) {
    return true
  } else return false
}

export function isSamlSsoLoginError(): boolean {
  if (window.location.href.indexOf('saml/error') > -1) {
    return true
  } else return false
}

export function isCompleteSsoRegistration(): boolean {
  if (
    window.location.href.indexOf(REG_ROUTE_REGISTER_SSO_COMPLETEPROFILE) > -1
  ) {
    return true
  } else return false
}

export function isPreregistration(): boolean {
  //preregistration processing prefix, sample incoming  http://localhost:3000?prc=123

  if (window.location.href.indexOf(URL_PARAM_PREREGISTRATION_CODE) > -1) {
    return true
  } else return false
}

function isEmpty(val?: string) {
  return val === undefined || val == null || val.length <= 0 ? true : false
}

export function checkMicroServiceResponseStatus(
  resp: any,
  allowUndefinedResp = false,
): {
  isError: boolean
  errorMsg: string
} {
  if (!resp) {
    if (!allowUndefinedResp) {
      return { isError: true, errorMsg: 'Response object is undefined' }
    }
  }
  let isError = false
  //review these with backend
  if (resp && resp.httpStatusCode === 500) {
    isError = true
  }
  if (resp && resp.status >= 400 && resp.status < 500) {
    isError = true
  }
  if (resp && resp.status === 404) {
    isError = true
  }
  if (resp && resp.status === 403) {
    isError = true
  }
  if (resp && resp.scriptaErrorCode) {
    isError = true
  }
  let errorMsg = ''
  if (resp && (resp as any).error) {
    errorMsg = JSON.stringify((resp as any).error)
  }
  if (resp && (resp as any).message) {
    errorMsg = (resp as any).message
  }
  return { isError, errorMsg }
}

export function getMoreDetailsFromNetworkError(e: any) {
  let errorDetails = {
    message: 'Unknown error',
    name: 'UnknownError',
    stack: undefined,
    cause: undefined,
  } as any

  if (e instanceof Error) {
    errorDetails = {
      message: e.message,
      name: e.name,
      stack: e.stack,
      cause: e.cause,
    }
  }

  if (e instanceof TypeError) {
    // Specific to network errors
    errorDetails.type = 'Network error'
  }

  if (e instanceof Response) {
    // For fetch responses that weren't caught earlier
    errorDetails.status = e.status
    errorDetails.statusText = e.statusText
    errorDetails.url = e.url
  }
  return errorDetails
}

export interface UiParsedError {
  message: string
  name?: string
  stack?: string
  code?: string | number
  status?: number
  details?: unknown
}

export function parseUiError(error: unknown): string {
  // Handle null or undefined
  if (error === null || error === undefined) {
    return 'No error details available'
  }

  // Handle string errors, including empty strings
  if (typeof error === 'string') {
    return error.trim() || 'Empty error message'
  }

  // Handle Error objects and derivatives
  if (error instanceof Error) {
    const parsed: UiParsedError = {
      message: error.message || 'Unknown error',
      name: error.name || 'Error',
      stack: error.stack ? error.stack : undefined,
    }

    // Safely handle axios/fetch errors
    const anyError = error as any
    if (anyError?.response) {
      if (typeof anyError.response.status === 'number') {
        parsed.status = anyError.response.status
      }
      if (anyError.response.data !== undefined) {
        parsed.details = anyError.response.data
      }
    }

    // Safely handle custom error properties
    if (
      anyError?.code !== undefined &&
      (typeof anyError.code === 'string' || typeof anyError.code === 'number')
    ) {
      parsed.code = anyError.code
    }

    try {
      return JSON.stringify(parsed)
    } catch {
      return parsed.message || 'Error parsing error object'
    }
  }

  // Handle plain objects
  if (error && typeof error === 'object') {
    try {
      return JSON.stringify(error)
    } catch {
      return 'Unable to stringify error object'
    }
  }

  // Fallback for unexpected error types
  return `Unexpected error type: ${typeof error}`
}
