import { Box, Grid, Link } from '@mui/material'
import CardContainer from './CardContainer'
import step1IconSrc from './images/step_1.svg'
import step2IconSrc from './images/step_2.svg'
import step3IconSrc from './images/step_3.svg'
import step4IconSrc from './images/step_4.svg'
import step5IconSrc from './images/step_5.svg'
import step6IconSrc from './images/step_6.svg'
import STypography from './customcomponents/STypography'
import { SPACING } from './theme2'
import { useSColors } from '../styles/scripta-theme'
import { ThemedSVGRemote } from '../v2/ThemedSVGRemote'

export interface StepCardProps {
  step: number
  stepDescription?: string
  customStepContent?: React.ReactNode
  customBackgroundColor?: string
}

export default function StepCard({
  step,
  stepDescription,
  customStepContent,
  customBackgroundColor,
}: StepCardProps) {
  const { COLORS } = useSColors()

  const stepIcon: {
    [key: number]: string
  } = {
    1: step1IconSrc,
    2: step2IconSrc,
    3: step3IconSrc,
    4: step4IconSrc,
    5: step5IconSrc,
    6: step6IconSrc,
  }
  return (
    <CardContainer
      sx={{
        backgroundColor: customBackgroundColor || COLORS.PRIMARY_10,
        borderColor: COLORS.BORDER_BRAND,
        padding: SPACING._05,
        boxShadow: '0px 4px 12px 0px rgba(77, 0, 140, 0.15);',
      }}
    >
      <Grid
        container
        gap={SPACING._075}
        flexWrap={'nowrap'}
        alignItems={'center'}
      >
        <Grid item alignSelf={'flex-start'} sx={{ height: '48px' }}>
          <ThemedSVGRemote
            importUrl={stepIcon[step]}
            alt={`Step ${step}`}
            width={40}
            height={48}
          />

          {/* <img src={stepIcon[step]} alt={`Step ${step}`} width={40} /> */}
        </Grid>
        <Grid item>
          {customStepContent ? (
            <STypography
              variant="body_regular"
              component={'div'}
              sx={{ color: COLORS.TEXT_BRAND }}
            >
              {customStepContent}
            </STypography>
          ) : (
            <STypography
              variant="body_regular"
              sx={{ color: COLORS.TEXT_BRAND }}
            >
              {stepDescription}
            </STypography>
          )}
        </Grid>
      </Grid>
    </CardContainer>
  )
}
