export enum SavingStrategy {
  //the position of these is reflected in the list of strategies ui component
  $4$9Program = '$4/$9 Program',
  AlternateGeneric = 'Alternate Generic',
  Coupons = 'Coupons',
  FormSwitch = 'Form Switch',
  FrequencyChange = 'Frequency Change',
  Generic = 'Generic',
  MultiPill = 'Multi Pill',
  NewBrand = 'New Brand',
  OTC = 'OTC',
  PillSplit = 'Pill Split',
  Related = 'Related',
  Separate = 'Separate',
  Unknown = 'Unknown',
  PharmacySwitch = 'Pharmacy Change',
}

//function to get all strategies
export const getAllStrategiesExceptUnknown = () => {
  return Object.values(SavingStrategy).filter(
    (strategy) => strategy !== SavingStrategy.Unknown,
  )
}
