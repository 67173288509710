import MobileStepper, {
  MobileStepperProps,
  mobileStepperClasses,
} from '@mui/material/MobileStepper'
import { useSColors } from 'src/app/styles/scripta-theme'
import { SPACING } from '../theme2'
import { SArrowButton } from 'src/app/system/customcomponents/SArrowButtons'

export interface SDotsStepperProps extends MobileStepperProps {
  activeStep: number
  onStepChange: (step: number) => void
  color?: 'primary' | 'secondary'
  shouldAutoDisableButtons?: boolean
  buttonSx?: any
}

export default function SDotsStepper({
  activeStep,
  onStepChange,
  steps,
  color = 'primary',
  shouldAutoDisableButtons = true,
  buttonSx = {},
  ...props
}: SDotsStepperProps) {
  const { COLORS } = useSColors()

  const DOT_COLORS =
    color === 'primary'
      ? {
          [`& .${mobileStepperClasses.dot}`]: {
            backgroundColor: '#FFF',
            border: `1.5px solid ${COLORS.TEXT_BRAND}`,
            height: '8px',
            width: '8px',
            margin: `0px ${SPACING._075}`,
            marginTop: '1.5px',
          },
          [`& .${mobileStepperClasses.dotActive}`]: {
            border: `1.5px solid ${COLORS.TEXT_BRAND}`,
            backgroundColor: COLORS.TEXT_BRAND,
            height: '12px',
            width: '12px',
            margin: `0px ${SPACING._075}`,
          },
        }
      : {
          [`& .${mobileStepperClasses.dot}`]: {
            backgroundColor: 'transparent',
            border: `1.5px solid ${COLORS.BORDER_GREY_LIGHT}`,
            height: '8px',
            width: '8px',
            margin: `0px ${SPACING._075}`,
            marginTop: '1.5px',
          },
          [`& .${mobileStepperClasses.dotActive}`]: {
            border: `1.5px solid ${COLORS.TEXT_LIGHT_PRIMARY_WHITE}`,
            backgroundColor: COLORS.TEXT_LIGHT_PRIMARY_WHITE,
            height: '12px',
            width: '12px',
            margin: `0px ${SPACING._075}`,
          },
        }

  const handleNext = () => {
    onStepChange(activeStep + 1)
  }

  const handleBack = () => {
    onStepChange(activeStep - 1)
  }

  return (
    <MobileStepper
      variant="dots"
      steps={steps}
      position="static"
      activeStep={activeStep}
      sx={{
        padding: 0,
        flexGrow: 1,
        ...DOT_COLORS,
      }}
      {...props}
      nextButton={
        <SArrowButton
          direction="right"
          onClick={handleNext}
          disabled={
            !shouldAutoDisableButtons ? false : activeStep === steps - 1
          }
          color={color}
          buttonSx={buttonSx}
        />
      }
      backButton={
        <SArrowButton
          direction="left"
          onClick={handleBack}
          disabled={!shouldAutoDisableButtons ? false : activeStep === 0}
          color={color}
          buttonSx={buttonSx}
        />
      }
    />
  )
}
