import { useEffect, useState } from 'react'
import { IS_DEV, IS_NATIVE } from '../apiexec/utils'
import { ROUTE_COMPLETE_LOGIN } from '../PortalRoutes'
import { usePortalAppConfig } from '../config/usePortalAppConfig'
import DataNotFoundImage from './../../images/data_not_found.png'
import OfflineLogo from '../../images/logo.png'
import { useMemberAppContext } from '../MemberAppContext'
import history from 'src/app/scripta-browser-history'
import { REG_ROUTE_LOGIN } from '../registration/RegistrationSubRoutes'
import { useLocation } from 'react-router-dom'
import { URL_PARAM_MOBILE_APP_VERSION } from './ForceUpgradeDialog'

export function GenericErrorPage({
  additionalDetails,
  //extract from the url in the case of context error handler so we can maintain it when user clicks back to home page
  mobileVersionToRedirectTo,
}: {
  additionalDetails?: string
  mobileVersionToRedirectTo?: string
}) {
  console.log('generic error page render')
  const PORTAL_APP_CONFIG = usePortalAppConfig()
  const location = useLocation()

  const { mobileVersion, isSsoLogin } = useMemberAppContext()
  //also enable some additional details when routing via react router
  const [additionalRoutedErrorDetails, setAdditionalRoutedErrorDetails] =
    useState<string | undefined>()
  useEffect(() => {
    document.title = 'Error Page'
  })
  useEffect(() => {
    if (location?.state && typeof location.state === 'object') {
      const state = location.state as { additionalRoutedErrorDetails?: string }
      if (state.additionalRoutedErrorDetails) {
        setAdditionalRoutedErrorDetails(state.additionalRoutedErrorDetails)
      }
    }
  }, [location])

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '40px',
      }}
    >
      <div>
        <img alt="Logo" src={OfflineLogo} height="50px" />
      </div>
      <div>
        <img alt="Error Page" src={DataNotFoundImage} height="150px" />
      </div>
      <p style={{ textAlign: 'center', fontFamily: 'Proxima Nova,Roboto' }}>
        Sorry, something went wrong. We're working on fixing this as soon as we
        can.
      </p>
      {additionalRoutedErrorDetails && (
        <p style={{ fontFamily: 'Proxima Nova,Roboto' }}>
          Additional Details: {additionalRoutedErrorDetails}
        </p>
      )}
      {additionalDetails && (IS_DEV() || IS_NATIVE()) && (
        <p style={{ fontFamily: 'Proxima Nova,Roboto' }}>
          Additional Details: {additionalDetails}
        </p>
      )}
      {!isSsoLogin && (
        <div style={{ marginTop: '20px' }}>
          <button
            style={{
              padding: '8px 16px',
              fontSize: '14px',
              cursor: 'pointer',
              border: '1px solid #ccc',
              borderRadius: '4px',
              background: '#fff',
            }}
            onClick={() => {
              let targetUrl = REG_ROUTE_LOGIN
              let versionParam = undefined
              if (mobileVersionToRedirectTo) {
                versionParam = mobileVersionToRedirectTo
              } else if (mobileVersion && typeof mobileVersion === 'string') {
                versionParam = mobileVersion
              }
              if (versionParam) {
                targetUrl =
                  targetUrl +
                  '?' +
                  URL_PARAM_MOBILE_APP_VERSION +
                  '=' +
                  versionParam
              }
              ;(window as Window).location = targetUrl as any
            }}
          >
            Back to the home page
          </button>
        </div>
      )}

      {isSsoLogin && (
        <div style={{ textAlign: 'center' }}>
          <p
            style={{
              textAlign: 'center',
              paddingTop: '20px',
              fontFamily: 'Proxima Nova,Roboto',
            }}
          >
            Please try your request again. If the issue persists, please contact
            support.
          </p>
          <button
            style={{
              padding: '8px 16px',
              fontSize: '14px',
              cursor: 'pointer',
              border: '1px solid #ccc',
              borderRadius: '4px',
              background: '#fff',
              marginTop: '16px',
            }}
            onClick={() => {
              history.push(ROUTE_COMPLETE_LOGIN)
            }}
          >
            Back to the home page
          </button>
        </div>
      )}

      <div
        style={{
          fontSize: '1.5rem',
          marginTop: '20px',
          fontFamily: 'Proxima Nova,Roboto',
          display: 'flex',
          gap: '3px',
          flexWrap: 'wrap',
          justifyContent: 'center',
        }}
      >
        <span>Need help?</span>
        <span>
          E-mail{' '}
          <a
            href={`mailto:${PORTAL_APP_CONFIG.MEMBER_SUPPORT_EMAIL}`}
            style={{ textDecoration: 'underline', fontWeight: 600 }}
          >
            {PORTAL_APP_CONFIG.MEMBER_SUPPORT_EMAIL}
          </a>{' '}
          or
        </span>
        <span>
          Call{' '}
          <a
            href={`tel:${PORTAL_APP_CONFIG.MEMBER_SUPPORT_NUMBER}`}
            style={{ textDecoration: 'underline', fontWeight: 600 }}
          >
            {PORTAL_APP_CONFIG.MEMBER_SUPPORT_NUMBER}
          </a>
        </span>
      </div>
    </div>
  )
}
