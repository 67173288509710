import React, { Component, ErrorInfo, ReactNode } from 'react'
import { GenericErrorPage } from './GenericErrorPage'
import { LOG } from '../v2/applog'
import { IS_DEV } from 'src/app/apiexec/utils'

interface Props {
  children: ReactNode
}

interface State {
  hasError: boolean
  additionalDetails: string
}

export class ErrorBoundaryComponentLevel extends Component<Props, State> {
  public state: State = {
    hasError: false,
    additionalDetails: '',
  }

  public componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    LOG.warn('app_error_boundary', 'Generic App Erorr Boundary component', {
      error: error.message,
      stack: error.stack,
    })

    this.setState({ hasError: true, additionalDetails: error.message })
  }

  public render() {
    if (this.state.hasError) {
      return (
        <GenericErrorPage additionalDetails={this.state.additionalDetails} />
      )
    }

    return this.props.children
  }
}
