import { Router } from 'react-router-dom'
import history from 'src/app/scripta-browser-history'
import { IS_DEV, IS_NATIVE } from './apiexec/utils'
import { ErrorBoundaryComponentLevel } from './application/ErrorBoundaryComponentLevel'

import { ForceUpgradeDialogContainer } from './application/ForceUpgradeDialog'
import { PortalMain } from './application/PortalMain'
import { ToastProvider } from './application/ScriptaSnackbarProvider'
import { MemberAppProvider } from './MemberAppContext'
import { PortalPublicRoutes } from './PortalRoutes'
import { PortalThemeProvider } from './styles/PortalThemeContext'
import ZohoSurveyContainer from 'src/app/system/zoho/ZohoSurveyContainer'
// import { ManualThemePicker } from 'src/app/styles/ManualThemePicker'

//trigger aws amplify rebuild
export function App() {
  //if dev and native mode - give hint to update API endpoint manually and comment out this line
  if (IS_NATIVE() && IS_DEV()) {
    return (
      <div style={{ marginTop: '200px' }}>
        It appears you running inside local development mode with react native
        webview. This error is returned intentionally to give you a hint to
        update the API_SERVICES_DEV_VIA_NATIVEIP4 inside code to match your
        local IP4 address AND pdate DisableCorsConfiguration in the java member
        services backend. At the moment its the only way possible to develop
        locally all 3 - react native, local portal ui and local spring boot
        services.
        <div>
          Then <b>comment out this whole if section in ui code</b> to continue
          working on your feature, and when finish, uncomment it out for the
          future.
        </div>
      </div>
    )
  }

  return (
    <Router history={history}>
      {/* <ErrorBoundaryContextLevel> */}
      <MemberAppProvider>
        <ErrorBoundaryComponentLevel>
          <PortalThemeProvider>
            <ToastProvider>
              {/* {IS_DEV() && <ManualThemePicker />} */}

              <ZohoSurveyContainer />
              <ForceUpgradeDialogContainer />
              <PortalPublicRoutes />
              <PortalMain />
            </ToastProvider>
          </PortalThemeProvider>
        </ErrorBoundaryComponentLevel>
      </MemberAppProvider>
      {/* </ErrorBoundaryContextLevel> */}
    </Router>
  )
}

export default App
