import { IconButton } from '@mui/material'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faChevronLeft,
  faChevronRight,
  faChevronUp,
  faChevronDown,
} from '@fortawesome/pro-regular-svg-icons'
import { useSColors } from 'src/app/styles/scripta-theme'
import { IconDefinition } from '@fortawesome/fontawesome-svg-core'

interface SArrowButtonProps {
  onClick: () => void
  disabled?: boolean
  color?: 'primary' | 'secondary'
  buttonSx?: any
  direction: 'left' | 'right' | 'up' | 'down'
}

export function SArrowButton({
  onClick,
  disabled,
  color = 'primary',
  buttonSx = {},
  direction,
}: SArrowButtonProps) {
  const { COLORS } = useSColors()

  const BUTTON_COLORS =
    color === 'primary'
      ? {
          backgroundColor: COLORS.PRIMARY_BRAND,
          color: COLORS.TEXT_LIGHT_PRIMARY_WHITE,
          width: '32px',
          height: '32px',
          '&:disabled': {
            backgroundColor: COLORS.BORDER_GREY_LIGHT,
            color: COLORS.TEXT_LIGHT_PRIMARY_WHITE,
          },
          '&:hover': {
            border: `1.5px solid ${COLORS.TEXT_BRAND}`,
            backgroundColor: COLORS.TEXT_BRAND,
          },
        }
      : {
          backgroundColor: COLORS.PRIMARY_20,
          color: COLORS.TEXT_BRAND,
          width: '32px',
          height: '32px',
          '&:disabled': {
            backgroundColor: 'rgba(255, 255, 255, 0.20)',
            color: 'rgba(255, 255, 255, 0.20)',
          },
          '&:hover': {
            border: `1.5px solid ${COLORS.PRIMARY_40}`,
            backgroundColor: COLORS.PRIMARY_40,
          },
        }

  const directionMap: Record<string, IconDefinition> = {
    left: faChevronLeft,
    right: faChevronRight,
    up: faChevronUp,
    down: faChevronDown,
  }

  return (
    <IconButton
      data-testid={`dots-stepper-${direction}-button`}
      aria-label={direction}
      sx={{
        ...BUTTON_COLORS,
        ...buttonSx,
      }}
      disabled={disabled}
      onClick={onClick}
    >
      <FontAwesomeIcon
        icon={directionMap[direction]}
        style={{ width: '16px', height: '16px' }}
      />
    </IconButton>
  )
}
