import { Box, Grid, Icon, Typography } from '@mui/material'
import PLAYSTORE_LOGO from '../../images/playstore.png'
import APPSTORE_LOGO from '../../images/apple.png'
import FORCE_UPGRADE_ICON from '../../images/force_upgrade.svg'
import {
  IOS_APP_TARGET_LINK,
  GOOGLE_PLAY_TARGET_LINK,
} from '../widgets/MobileAppLinks'
import Dialog from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'
import {
  isAndroid,
  isIOS,
  IS_NATIVE,
  nativeApi_appReady,
} from '../apiexec/utils'
import { useMemberAppContext } from '../MemberAppContext'
import { useCallback, useEffect, useState } from 'react'
import { useSTranslate } from 'src/app/hooks/useSTranslate'
import CloseIcon from '@mui/icons-material/Close'
import SButton from '../system/customcomponents/SButton'
import { LOG } from 'src/app/v2/applog'
const imageStyle = {
  width: '150px',
  maxWidth: '150px',
  minWidth: '150px',
}
//version shown for display purposes, can be useful to quickly confirm latest build has been deployed
//its not used for any official versioning, only update if needed
//just displayed at the very bottom of footer or force upgrade dialog
const CURRENT_WEB_VERSION_DISPLAY_ID = '3.0.0'

const SUPPORTED_VERSIONS = new Set(['3.0.0'])
export const MIN_SUPPORTED_ANDROID_APP_VERSION = '3.0.2'
export const SUPPORTED_ANDROID_VERSIONS = new Set(['3.0.2', '3.0.3'])
//small helper string to show native, ui and instance (render) id
export function getFullVersionDisplayId(
  mobileVersion: string | null | undefined,
) {
  //just a random number to show render id
  //this number changing = app reloaded/webview refreshed
  const renderInstanceId = Math.floor(Math.random() * 1000)
  return `N:${
    mobileVersion ? mobileVersion : 'NA'
  }|W:${CURRENT_WEB_VERSION_DISPLAY_ID}-${renderInstanceId}`
}
export const URL_PARAM_MOBILE_APP_VERSION = 'mobileAppVersion'

function getMobileAppVersionFromUrlParameter() {
  const windowUrl = window.location.search
  const params = new URLSearchParams(windowUrl)
  const mobileAppVersion: string | null = params.get(
    URL_PARAM_MOBILE_APP_VERSION,
  )
  return mobileAppVersion
}

export function ForceUpgradeDialogContainer() {
  //store this in context, otherwise it will be lost from url after react router redirects to login or elsewhere
  //we may need this later to for. example call differnet api backend version
  const { mobileVersion, setMobileVersion } = useMemberAppContext()

  const [fullId, setFullId] = useState<string>()

  const [messageReceived, setMessageReceived] = useState<boolean>(false)

  //give a fail safe to user to skip the upgrade
  const [userWantsToSkip, setUserWantsToSkip] = useState(false)

  useEffect(() => {
    const handleMessage = (event: any) => {
      const data = JSON.parse(event.data)

      if (data.mobileVersion) {
        setMobileVersion(data.mobileVersion)
        setFullId(getFullVersionDisplayId(data.mobileVersion))
        setMessageReceived(true)
      }
    }

    // https://stackoverflow.com/questions/41160221/react-native-webview-postmessage-does-not-work
    if (IS_NATIVE()) {
      if (isAndroid()) {
        document.addEventListener('message', handleMessage)
        return () => {
          document.removeEventListener('message', handleMessage)
        }
      } else {
        window.addEventListener('message', handleMessage)
        return () => {
          window.removeEventListener('message', handleMessage)
        }
      }
    }
  }, [])

  useEffect(() => {
    if (IS_NATIVE()) {
      nativeApi_appReady()
    }
  }, [])

  const shouldForceUpgrade = useCallback(() => {
    if (userWantsToSkip) {
      return false
    }
    // Check if message has been received or if timeout occurred before showing the modal
    if (IS_NATIVE() && !messageReceived) {
      return false
    }

    if (IS_NATIVE()) {
      const uA =
        navigator.userAgent || navigator.vendor || (window as any).opera
      // only mobile app version 2.0.0 or later will be passing mobileAppVersion
      if (!mobileVersion) {
        LOG.warn(
          'mobile_force_upgrade',
          `Force upgrade modal shown, missing mobileVersion. Mobile version: ${mobileVersion}, uA: ${uA}`,
        )
        return true
      }

      // mobileAppVersion is no longer supported
      if (mobileVersion && !SUPPORTED_VERSIONS.has(mobileVersion)) {
        LOG.warn(
          'mobile_force_upgrade',
          `Force upgrade modal shown, mobileVersion not supported. Mobile version: ${mobileVersion}, uA: ${uA}`,
        )
        return true
      }
    }

    return false
  }, [messageReceived, mobileVersion, userWantsToSkip])

  if (!IS_NATIVE()) {
    return null
  }

  return null

  return (
    <ForceUpgradeDialog
      open={shouldForceUpgrade()}
      onClose={() => setUserWantsToSkip(true)}
    />
  )
}

export const isVersionSupported = (
  minSupportedVersion: string,
  mobileVersion: string,
): boolean => {
  const minVersionParts = minSupportedVersion.split('.').map(Number)
  const mobileVersionParts = mobileVersion.split('.').map(Number)

  for (let i = 0; i < minVersionParts.length; i++) {
    if (mobileVersionParts[i] > minVersionParts[i]) {
      return true
    } else if (mobileVersionParts[i] < minVersionParts[i]) {
      return false
    }
  }

  return true
}

interface ForceUpgradeDialogProps {
  open: boolean
  onClose: () => void
  fullId?: string
}

export const ForceUpgradeDialog: React.FC<ForceUpgradeDialogProps> = ({
  open,
  onClose,
  fullId,
}) => {
  const { t } = useSTranslate('application')

  function getStoreButton() {
    const googlePlayButton: any = (
      <Grid item xs={12}>
        <a href={GOOGLE_PLAY_TARGET_LINK} target="_blank">
          <img
            src={PLAYSTORE_LOGO}
            alt="Download from Google Play Store"
            style={imageStyle}
            height={'50px'}
          />
        </a>
      </Grid>
    )

    const appStoreButton: any = (
      <Grid item xs={12}>
        <a href={IOS_APP_TARGET_LINK} target="_blank">
          <img
            src={APPSTORE_LOGO}
            alt="Download from IOS Store"
            style={imageStyle}
            height={'50px'}
          />
        </a>
      </Grid>
    )

    if (isAndroid()) {
      return googlePlayButton
    }

    if (isIOS()) {
      return appStoreButton
    }

    return (
      <>
        {googlePlayButton}
        {appStoreButton}
      </>
    )
  }

  return (
    <Dialog
      open={open}
      PaperProps={{
        style: { borderRadius: '20px' },
      }}
    >
      <DialogContent sx={{ maxWidth: '350px', textAlign: 'center' }}>
        <Box sx={{ display: 'flex' }}>
          <Box sx={{ marginLeft: 'auto', cursor: 'pointer' }}>
            <CloseIcon onClick={onClose} />
          </Box>
        </Box>
        <Box sx={{ padding: 2 }}>
          <img
            src={FORCE_UPGRADE_ICON}
            alt="force upgrade icon"
            width={'48px'}
            height={'48px'}
          />
        </Box>
        <Typography fontWeight={700} fontSize={20} textAlign={'center'}>
          {t('newVersionReq')}
        </Typography>
        <Grid container>
          <Grid item xs={12}>
            <Typography fontSize={13} fontWeight={400}>
              {t('upgradeAppToLatestVersion')}
            </Typography>
          </Grid>
          <Grid container spacing={2} sx={{ marginTop: 1 }}>
            {getStoreButton()}
          </Grid>
          <Grid container spacing={2} sx={{ marginTop: 1 }}>
            <Grid item xs={12}>
              <Typography sx={{ fontSize: '10px' }}>{fullId}</Typography>
            </Grid>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  )
}
